import React, { useEffect, useState} from 'react';
import Toast from '@salesforce/design-system-react/components/toast'; 
import ToastContainer from '@salesforce/design-system-react/components/toast/container'; 
import IconSettings from '@salesforce/design-system-react/components/icon-settings';

export default function CQToast(props) {
    const [heading, setHeading] = useState('');
    useEffect(()=> {
        setHeading (props.heading);
    },[]);
    const closeClicked = () => {
        setHeading('');
    }

    return (
        <IconSettings iconPath="/assets/icons">
            <ToastContainer  className={props.className ? props.className : ''}>
                {heading !== '' ?
                <Toast
                    labels={{
                        heading:`${heading}`,
                        details: props.detail ? props.detail : null
                    }}
                    variant= {props.variant}
                    duration={props.duration}
                    onRequestClose={closeClicked}
                />:''}
            </ToastContainer>
        </IconSettings>
    )
}
