import React from "react";
import CQToast from "components/CQToast/CQToast";
import formBuilderScreenStrings from 'localizations/formBuilderScreen';

const CQHeaderToast = ({ success, message }: any) => {
  return (
    <React.Fragment>
        <div>
            <CQToast variant={success ? "success": "error"}
                heading={success ? formBuilderScreenStrings.saved : formBuilderScreenStrings.saveFailed}
                detail={message} 
                className='cq-header-toast'
            />
        </div>
    </React.Fragment>
  );
};

export default CQHeaderToast;
