import React, { useContext, useEffect, useRef, useState} from 'react';
import { useJsonForms, withJsonFormsControlProps } from '@jsonforms/react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CQProgressBar from '../components/CQProgressBar';
import CQFileUploaderContext from '../context/CQFileUploaderContext';
import strings from '../localizations/formScreen';

import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon'; 
import axios from 'axios';
import AccessManagerContext from 'context/AccessManagerContext';
import { isIOS } from 'react-device-detect';
import { isSalesforceBuild } from 'salesforceBuild';
import LCC from 'lightning-container';
import CQFileSaverContext from 'context/CQFileSaverContext';
 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loopButton: {
      width: '15px',
      height: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      float: 'right',
      top: '3rem',
      right: '-0.5rem'
    },
    errorMessage:{
      color: "red",
      fontSize: "x-small"
    }
  }),
);




const CQFileCard = ({data, handleChange, path, required, visible, label, uischema, errors, enabled}: any) => {

  const fileUploaderContext = useContext(CQFileUploaderContext);
  const classes = useStyles();
  const [progress, setProgress] = useState(-1);
  const [errorMessage, setErrorMessage] = useState('');
  const [indeterminate, setIndeterminate] = useState(false);
  const[imageURL, setImageURL] = useState<any>({});
  const accessManagerContext = useContext(AccessManagerContext);
  const fileSaverContext:any = useContext(CQFileSaverContext);

  const ctx = useJsonForms();
  const wholeDataObject : any = ctx.core?.data; // wholeDataObject stores the entire form data

  // Recursive function to count the total number of files
  const countFiles = (data) => {
    let fileCount = 0;

    if (Array.isArray(data)) {
      data.forEach(item => {
          fileCount += countFiles(item);
      });
    } else if (typeof data === 'object' && data !== null) {
      if (Array.isArray(data.Files)) {
          fileCount += data.Files.length;
      }
      Object.values(data).forEach(value => {
          fileCount += countFiles(value);
      });
    }

    return fileCount;
  }

  useEffect(()=> {
    if(data.progress !== undefined){
      if(data.error && data.ContentDocumentId === ''){
        data.error === 'Network Error'? setIndeterminate(true): setErrorMessage(strings.uploadFailed);
      }else if(data.error === '' && data.ContentDocumentId === ''){
        let fileInfo:any = fileUploaderContext.fileInfos.find((obj) => obj.LocalId === data.LocalId && obj.isUploading === false);
        if(fileInfo){
          if(fileInfo.ContentDocumentId !== ''){
            handleChange(path + '.ContentDocumentId' , fileInfo.ContentDocumentId);
            handleChange(path + '.error' , '');
            setErrorMessage('');
          }else{
            handleChange(path + '.error', fileInfo.error);
            setErrorMessage(strings.uploadFailed);
          }
        }else{
          setErrorMessage(strings.reUploadMessage);
        }
      }
    }
  }, []);

  useEffect(() => {
    fileUploaderContext.subscribeToProgressOf( data.LocalId, (msg) => {
      setErrorMessage('');
      setProgress(msg.progress);
      
      if(msg.status === 'uploaded'){
        handleChange(path + '.ContentDocumentId' , msg.ContentDocumentId);
        handleChange(path + '.error' , '');
        handleChange(path + '.progress' , msg.progress);
        setIndeterminate(false);
        
        setErrorMessage('');
      }else if(msg.status === 'error'){
        if(msg.errorMessage === "Network Error"){
          handleChange(path + '.error' , msg.errorMessage);
          handleChange(path + '.progress' , -1);
          setProgress(-1);
          setIndeterminate(true);
        }else{
          handleChange(path + '.error' , msg.errorMessage);
          handleChange(path + '.progress' , -1);
          setProgress(-1);
          setErrorMessage(strings.uploadFailed);
          setIndeterminate(false);
        }
      }
    });
  }, [fileUploaderContext, data])

  const handleRetry = () => {
    let fileInfo:any = fileUploaderContext.fileInfos.find((obj) => obj.LocalId === data.LocalId && obj.isUploading === false);
    if(fileInfo){
      if(fileInfo.ContentDocumentId !== ''){
        handleChange(path + '.ContentDocumentId' , fileInfo.ContentDocumentId);
        handleChange(path + '.error' , '');
        setErrorMessage('');
      }else{
        fileInfo.error = '';
        handleChange(path + '.error', '');
        fileUploaderContext.uploadFileToSF(fileInfo);
      }
    }else{
      setErrorMessage(strings.reUploadMessage);
    }
  }

  /**
   * This method sets the base64 value or url for image source
   */
  const getImageUrl = async () => {
    try {
      if (data.ContentDocumentId !== '') {
        let instanceURL = localStorage.getItem('instanceurl');
        const headers = {
          "Authorization" : `OAuth ${accessManagerContext.getUserContextSync().token}`
        };
        var requestOptions = {
          method: "GET",
          headers: headers
        };

        let uploadURL:any = instanceURL;
        let uploadPath:any;
        let networkId:any;

        // check if user is uploading file from internal org or external sites
        if(localStorage.getItem('userDetailsSF')) {
            const userDetails:any =  JSON.parse(localStorage.getItem('userDetailsSF') || '');
            if(userDetails['userType'] === 'POWER_PARTNER') {
                networkId = userDetails['networkId'];
                uploadPath = `/services/data/v62.0/connect/communities/${networkId}/files/${data.ContentDocumentId}/previews/thumbnail`;
                uploadURL = userDetails['siteUrl'];
            } else {
                uploadPath = `/services/data/v62.0/connect/files/${data.ContentDocumentId}/previews/thumbnail`;
            }
        } else {
            uploadPath = `/services/data/v62.0/connect/files/${data.ContentDocumentId}/previews/thumbnail`;
        }

        await axios.get(`${uploadURL}${uploadPath}`, {
          headers: headers
        }).then(async(response:any) => {
          // This needs to be set in data to fetch files when puppeteer creates submission pdf
          data.accessToken = (accessManagerContext.getUserContextSync().token && accessManagerContext.getUserContextSync().token !== '') ? accessManagerContext.getUserContextSync().token: data.accessToken;
          data.originUrl = (localStorage.getItem('targetOrigin') && localStorage.getItem('targetOrigin') !== null) ? localStorage.getItem('targetOrigin'):data.originUrl;
          if(response.data.previewUrls.length > 0) {
            return await fetch(
            `${instanceURL}${response.data.previewUrls[0].previewUrl}`,
              requestOptions
            ).then((response) => {
              if(response){
                  response.blob().then((blobResponse: any) => {
                    if(blobResponse){
                      let blobData = blobResponse;
                      var reader = new FileReader();
                      reader.readAsDataURL(blobData); 
                      reader.onloadend = async function() {
                        var base64data:any = reader.result;                
                        setImageURL({url: base64data, type: data.Name.split('.')[1]});
                        let response = await fileUploaderContext.getCachedImageFromId(data.LocalId);
                        if(response && Object.keys(response).length) {
                          await fileUploaderContext.deleteCachedImageFromId(data.LocalId);
                        }
                      }
                    }
                  });
              }      
            })
          } else {
            let response = await fileUploaderContext.getCachedImageFromId(data.LocalId);
            if(response) {
              setImageURL({url: response.result, type: data.Name.split('.')[1]});
            }
          }
        })
      } else {
       let response = await fileUploaderContext.getCachedImageFromId(data.LocalId);
        if(response) {
          setImageURL({url: response.result, type: data.Name.split('.')[1]});
        }
      }
    } catch(err){
      if(localStorage.getItem('targetOrigin') == null && data.originUrl !== localStorage.getItem('targetOrigin')) {
        await getPdfImageUrl();
      }
      console.error(err);
    };
  }

  useEffect(() => {
    let interval;
    let messageHandler;
    if (isSalesforceBuild()) {
      if (data.ContentDocumentId !== "") {
        messageHandler = function (message) {
          console.log("Received message second", message);
          if (Object.keys(fileSaverContext[data.ContentDocumentId]).length === 0) {
            if(data.ContentDocumentId === message.contentDocumentId) {
              setImageURL({
                id: message.contentDocumentId,
                url: message.previewUrl,
                type: data.Name.split(".")[1],
              });
            }
          }
        };
        LCC.addMessageHandler(messageHandler);
        interval = setInterval(() => {
          if (Object.keys(fileSaverContext[data.ContentDocumentId]).length === 0) {
            LCC.sendMessage({
              instructions: "contentInformation",
              contentId: data.ContentDocumentId,
            });
          }
        }, 500);
      }
    } else {
      getImageUrl();
    }

    return () => {
      clearInterval(interval)
      LCC.removeMessageHandler(messageHandler);
    };
  }, [data, fileSaverContext]);

  // This useEffect set the href of the anchor tag based on instanceURL and Content Document Id for each link
  useEffect(() => {
    const linkElements = document.querySelectorAll('.cq-non-image-link') as NodeListOf<HTMLAnchorElement>;
    const handleBeforePrint = () => {
      linkElements.forEach(linkElement => {
        linkElement.href = `${(isSalesforceBuild() ? window.location.ancestorOrigins[0] : localStorage.getItem('instanceurl') ? localStorage.getItem('instanceurl'):data.originUrl)}/${data.ContentDocumentId}`;
      });
    };
    const handleAfterPrint = () => {
      linkElements.forEach(linkElement => {
        linkElement.href = imageURL.url;
      });
    }
    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);
    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  
  }, [imageURL]);

  /**
   * This methods fetch file when pupeteer creates pdf from submission
   */
  const totalFiles = useRef(countFiles(wholeDataObject));
  const getPdfImageUrl = async() => {
    if(data.hasOwnProperty('originUrl') && data.hasOwnProperty('accessToken')) {
      await axios.post(`/api/fetchUrl?originUrl=${data?.originUrl}`, {
        accessToken: data?.accessToken,
        contentDocumentId: data?.ContentDocumentId
      }, {
        responseType: 'blob'
      }).then(async(response:any) => {
        var reader = new FileReader();
        reader.readAsDataURL(response.data);   
        reader.onloadend = function() {
          var base64data = reader.result;                
          setImageURL({
            id: data.ContentDocumentId,
            url: base64data,
            type: data.Name.split(".")[1],
          });
          // Check if all requests are complete in case of error
          const allFiles = document.querySelectorAll('.cq-non-image-link');
          if(allFiles.length === totalFiles.current) {
            document.body.setAttribute('data-image-ready', 'true');
          }
        }
      }).catch( (ex) => {
        console.error(ex);
      })
    }
  }

  return (
    <>
    {visible?
    <CQFileSaverContext.Provider value={Object.assign(fileSaverContext, {[data.ContentDocumentId]: imageURL})}>
      <IconSettings iconPath="/assets/icons"> 
        <div className="slds-grid slds-wrap">
          <div className="slds-col slds-size_1-of-1">
            {Object.keys(imageURL).length 
              ? imageURL.type.toLowerCase() === 'png' || imageURL.type.toLowerCase() === 'jpg' || imageURL.type.toLowerCase() === 'jpeg' 
                ? <a href={isSalesforceBuild() ? fileSaverContext[imageURL.id].url: imageURL.url} rel='noreferrer' target='_blank' className='cq-non-image-link' download>
                    <img src={isSalesforceBuild() ? fileSaverContext[imageURL.id].url: imageURL.url} alt={strings.imageAltText} className='cq-form-image'/>
                  </a>
                : isIOS
                  ? <a href={isSalesforceBuild() ? fileSaverContext[imageURL.id].url : imageURL.url} rel='noreferrer' target='_blank' className='cq-non-image-link'>
                    <span>{data.Name}</span>
                    </a> 
                  : <a href={isSalesforceBuild() ? fileSaverContext[imageURL.id].url : imageURL.url} rel='noreferrer' target='_blank' className='cq-non-image-link' download>
                    <span>{data.Name}</span>
                    </a>
              : isSalesforceBuild() ?
                <a href={`${window.location.ancestorOrigins[0]}/${data.ContentDocumentId}`} rel='noreferrer' target='_blank' className='cq-non-image-link'>{data.Name}</a> 
                :<span className='cq-non-image-span'>{data.Name}</span>
            }
            {errorMessage !== ''? 
            <div className="slds-grid slds-wrap"> 
              <div className="slds-col slds-size_1-of-1">
                <span className={classes.errorMessage}>{errorMessage}</span>
              </div>
              <div className="slds-col slds-size_1-of-1">
                <button onClick={handleRetry}>
                  <Icon 
                    assistiveText={{ label: strings.retry }}
                    category="utility"
                    name="loop"
                    size="x-small"
                  />
                </button>
              </div>
            </div> : ''}
            <CQProgressBar indeterminate={indeterminate} progress={progress}/>
          </div>
        </div>
      </IconSettings>
    </CQFileSaverContext.Provider>
    :''}
    </>
  )
};


export default withJsonFormsControlProps(CQFileCard);