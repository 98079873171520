import moment from "moment";
import React from "react";
import { AccessManager } from "../../api/AccessManager";
import { addGeneralDataInSubmissionData } from "services/data-mapping.service";

export default function CQInProgressTab(props: any) {
  let dataKeys: any = "";
  let dataPropertiesKeys: any = [];
  let schemaPropertiesKey: any = [];

  // Removing unused keys and removing data with object as value and asssigning to dataKeys
  if (props!.item.data !== undefined && Object.keys(props!.item.data).length > 0) {
    dataKeys = Object.values(props!.item.data);
    dataKeys.forEach((dataKeyValueItem)=>{
      delete dataKeyValueItem["attributes"];
      dataPropertiesKeys = {...dataPropertiesKeys, ...dataKeyValueItem}
    });
    if(!props?.item?.data.hasOwnProperty('General')){
      addGeneralDataInSubmissionData(props.item);
    }
    Object.keys(dataPropertiesKeys).forEach((key) => {
      if (typeof dataPropertiesKeys[key] === "object" || dataPropertiesKeys[key] === undefined) {
        delete dataPropertiesKeys[key];
      }
    });
  }
  /**
   * This Method adds fields to be displayed as dragged by user from submission UI
   * @param uiElements 
   */  
  const storeSchemaPropertiesKey = (uiElements: any) => {
    let fieldTitle:string;
    // check if receieved uiElemenets has elements as "key" or not 
    if (!uiElements.hasOwnProperty("elements")) {
      uiElements.map((element:any) => {
        if (element.hasOwnProperty("scope")) { // check if element has scope
          let fieldApi = element.scope.split("/").pop(); // store api name of field e.g cqext_start_date__c
            Object.values(props.item.formDef.schema.properties).map((property: any) => {
                if(property.hasOwnProperty('properties')){
                  Object.keys(property["properties"]).map((key:any) => {
                    if (key === fieldApi) {  // check if incoming field is available inside schema properties or not
                      if (element.options.hasOwnProperty('title')){
                        fieldTitle = element['options']['title']; //store title of field e.g start date
                      } else {
                        fieldTitle = property["properties"][key]["title"];
                      }
                      //Check if element type is not equal to section or blank
                      if(element['type'] !== 'Section' && element['type'] !== 'blank'){
                        schemaPropertiesKey.push({
                          fieldDetail: Object.assign(property["properties"][key], {fieldApi}),
                          fieldTitle,
                        });
                      }
                    }
                  });
                } 
              }
            );
        } else {
          storeSchemaPropertiesKey(element);
        }
      });
    } else {
      storeSchemaPropertiesKey(uiElements.elements);
    }
  };

  // Gathering keys from ui elements filtering the odd one out
  if (
    props!.item.formDef.ui &&
    Object.keys(props!.item.formDef.ui.elements).length !== 0 // check if properties is empty in schema
  ) {
    if (
      props.item.formDef.ui.elements[0].elements &&
      props.item.formDef.ui.elements[0].elements.length !== 0
    ) {
      // Filter out the uiElements based on sectionId
      const newUIelement = props.item.formDef.ui.elements.filter((item => item.hasOwnProperty('sectionId')));
      storeSchemaPropertiesKey(newUIelement); // send fields containing ui
    }

    schemaPropertiesKey = schemaPropertiesKey.filter((elem, index) => index < props.totalItems);
  }

  const accessManager = new AccessManager();

  return (
    <React.Fragment>
      {schemaPropertiesKey.length > 0 ? (
        schemaPropertiesKey.map((field: any, index: number) => (
          <div
            className={
              schemaPropertiesKey.length < 5
                ? `slds-col slds-scrollable slds-m-bottom_x-small cq-inprogress-list`
                : "slds-col slds-scrollable slds-size_2-of-12 slds-m-bottom_x-small slds-wrap cq-inprogress-list"
            }
            key={index}
            style={{overflowWrap: "anywhere"}}
          >
            <p className="cq-inprogress-title" style={{ color: "grey" }}>
              {field.fieldTitle}
            </p>
            <p className="cq-inprogress-title">
              {field.fieldDetail.hasOwnProperty("format") && field.fieldDetail.format === "date-time" && dataPropertiesKeys[field.fieldDetail.fieldApi] !== undefined
                ? moment(
                    accessManager.getDateTimeStringInUserTimeZone(
                      dataPropertiesKeys[field.fieldDetail.fieldApi]
                    )
                  ).calendar()
                : dataPropertiesKeys[field.fieldDetail.fieldApi]}
            </p>
          </div>
        ))
      ) : (
        <div className="slds-col slds-size_10-of-12 cq-inprogress-list"></div>
      )}
    </React.Fragment>
  );
}
